import React, { FC, ReactNode } from 'react';
import HeaderNav from '../HeaderNav';
import Footer from '../Footer';
import Breadcrumb from '../Breadcrumb';
import * as s from './style';

export interface Props { children?: ReactNode, path: string };

const PageLayout: FC<Props> = ({ children, path }) => {

    return (
        <div >
            <HeaderNav />
            <span>{(path === '/') || <div style={{ margin: '30px 20px 0 20px' }} ><Breadcrumb path={path} /></div>}</span>
            <s.ChildContainer >
                {children}
            </s.ChildContainer>
            <Footer />
        </div>
    )
}

export default PageLayout;
