import { CSSProperties } from 'react';

export const displayFlex = ({ dir = 'row', justify = 'center', align = 'center',wrap='nowrap' }:
    { dir?: CSSProperties['flexDirection'], 
    justify?: CSSProperties['justifyContent'], 
    align?: CSSProperties['alignItems'],
    wrap?: CSSProperties['flexWrap'],
}) => {
    return `
        display: flex;
        flex-direction: ${dir};
        justify-content: ${justify};
        align-items: ${align};
        flex-wrap: ${wrap};

    `
}

export const screenWidths = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
  }