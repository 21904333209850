import { DefaultTheme } from 'styled-components';

export const appTheme: DefaultTheme = {
    borderRadius: '5px',
    padding: '5px',

    colors: {
        default: '#f7f5ee',
        primary: '#d8c3a5',
        secondary: '#e85a4f',
        third: '#8e8d8a',
        fourth: '#e98074',
        fifth: '#f5f5f5',
        focus: 'rgb(170,170,170)',
    },
};