import React, { FC } from 'react';
import { Facebook, Instagram, Gmail, Pinterest, Twitter } from 'mdi-material-ui';
import { Typography, IconButton } from '@material-ui/core'
import { appTheme } from '../../globalStyle/appTheme';
import styled from 'styled-components';
// const style = require('./Footer.module.scss'

export interface Props { };

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    // height: 250px;
    box-sizing: border-box;
    padding: 20px;
    > div{
        flex:30%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 5px;
    }
    @media (max-width: 768px){
        padding: 30px 20px 20px 20px;
        > div{
            padding: 0 35px;
            flex:100%;
            margin-bottom: 30px !important;
        }
    }
`
const About = styled.div`
    text-align: justify;
    min-width: 240px ;
`
const FollowLinks = styled.div`
     > div{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
`

const Footer: FC<Props> = () => {

    return (
        <Container style={{ backgroundColor: appTheme.colors.fifth }} >
            <div >
                <Typography variant='h2' >LOGO</Typography>
            </div>
            <About>
                <Typography variant='h6' >ABOUT US</Typography>
                <Typography variant='body2'  >
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquid perspiciatis soluta porro maiores dolorum. Magni harum iste ut, obcaecati eligendi excepturi repellat provident consequuntur nisi. Expedita eius ex, qui sint tempora, impedit, corrupti a molestias inventore illo voluptas quas nostrum!
                </Typography>
            </About>
            <FollowLinks >
                <Typography variant='h6' >FOLLOW US</Typography>
                <div>
                    <span><IconButton><Facebook /></IconButton></span>
                    <span><IconButton><Instagram /></IconButton></span>
                    <span><IconButton><Gmail /></IconButton></span>
                    <span><IconButton><Pinterest /></IconButton></span>
                    <span><IconButton><Twitter /></IconButton></span>
                </div>
            </FollowLinks>
        </Container>
    )
};

export default Footer;