import Swal, { SweetAlertIcon } from 'sweetalert2';


export const strToTitle = (str: string): string => {
    return `${str[0].toUpperCase()}${str.slice(1)}`
}

export const getFileExtension = (fileName: string) => {
    const reverseString = [...fileName].reverse().join('')
    const reverseExt = reverseString.split('.')[0]
    const ext = [...reverseExt].reverse().join('')
    if (fileName.length === ext.length) {
        return false
    }
    return ext
}

export const showAlert = (
  titleText = 'Something happened.',
  alertType?: SweetAlertIcon
): void => {
  Swal.fire({
    titleText,
    position: 'top-end',
    timer: 3000,
    timerProgressBar: true,
    toast: true,
    showConfirmButton: false,
    showCancelButton: true,
    cancelButtonText: 'Dismiss',
    icon: alertType,
    showClass: {
      popup: 'swal2-noanimation',
      backdrop: 'swal2-noanimation',
    },
    hideClass: {
      popup: '',
      backdrop: '',
    },
  });
};