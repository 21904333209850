import styled from 'styled-components';
import { displayFlex } from '../../utils/css-generators';
import { appTheme } from '../../globalStyle/appTheme';

export const Container = styled.div`
    background-color: ${appTheme.colors.default};
    user-select: none;
    width: 100%;
    height: 100px;
    ${displayFlex({ align:'center', justify:'normal'})}
    padding: 5px 10px;
    & > div{
        width:100%
    }
`

export const HamBurger = styled.div`
    ${displayFlex({ justify: 'flex-start', align: 'center' })}
    width: 20% !important;
    display: none;
    @media (max-width: 768px){
        display: unset;
    }
`

export const Logo = styled.div`
    ${displayFlex({ justify: 'flex-start', align: 'center' })}
     a {
        text-decoration: none ;
        color: ${appTheme.colors.default};
        
        img{
            /* box-sizing: unset;
            padding: 30px;
            border-radius: 60%;
            background: radial-gradient( white 15%, transparent 50%); */
        }
    }
    @media (max-width: 768px){
        width: 60% !important;
        justify-content: center;
    }
`

export const PageLinks = styled.div`
    ${displayFlex({ justify: 'flex-end', align: 'center' })}
    font-family: Arial, Helvetica, sans-serif;
    @media (max-width: 768px){
        visibility: hidden;
        width: 20% !important;
    }
`

export const UL = styled.ul`
    ${displayFlex({ align: 'center', justify:'normal',wrap:'wrap' })}
    height: 50px;
    overflow-y: hidden;
`

export const LI = styled.li`
    list-style: none;
    transition: 0.2s ease-in-out;
    
    & > a {
        /* color: #f7f5ee; */
        color: ${appTheme.colors.fourth};
        text-decoration: none !important;
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        transition: 0.2s ease-in-out;
    }
    &:hover {
        background-color: ${appTheme.colors.fourth};
        >a { color:#F7F5EE ;}
    }

`

// export const A = styled.a`
//     color: #f7f5ee;
//     text-decoration: none !important;
//     width: 100px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 15px;
//     transition: 0.2s ease-in-out;
// `