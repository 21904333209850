import React, { FC } from 'react';
import * as s from './style';
import { Link } from 'gatsby';
import { Typography, IconButton } from '@material-ui/core';
import { Menu } from 'mdi-material-ui';
import { appTheme } from '../../globalStyle/appTheme';
const logo = require('../../assets/logo.svg');

export interface Props { }

const NavBar: FC<Props> = () => {
    
    return (
        <s.Container >
            <s.HamBurger>
                <IconButton style={{ color: appTheme.colors.fourth }} ><Menu color='inherit' /></IconButton>
            </s.HamBurger>
            <s.Logo >
                <Link to='/' ><img width='80' src={logo} alt="logo" /></Link>
            </s.Logo>
            <s.PageLinks >
                <s.UL >
                    <s.LI><Link to="/" >Home</Link></s.LI>
                    <s.LI><Link to="/blogs" >Blogs</Link></s.LI>
                    <s.LI><Link to="/about" >About</Link></s.LI>
                    <s.LI><Link to="/contact" >Contact</Link></s.LI>
                </s.UL>
            </s.PageLinks>
        </s.Container>
    )
}

export default NavBar;
